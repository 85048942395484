const messages: I18NMessages = {
  about_novom: 'About Novom',

  cart_choose_for_me: 'Choose for me',
  cart_choose: 'I want to choose',
  cart_summary: 'Summary',
  cart_impact: 'Impact',
  cart_impact_description: 'For each MovOn VIP ticket sold',
  cart_impact_follow: '25% will be donated to a charity cause',
  cart_payment: 'Payment',
  cart_phone: 'Phone number',
  cart_email: 'Email address',
  cart_info_missing: 'Missing information',
  cart_phone_invalid: 'Invalid phone number',
  cart_email_invalid: 'Invalid email address',
  cart_email_instead: 'Use email instead',
  cart_phone_instead: 'Use phone number instead',
  cart_more_options: 'More options',
  cart_nickname: 'First name or nickname (optional)',
  cart_pay_google: 'Pay with G Pay',
  cart_pay_credit: 'Pay with credit card instead',
  cart_pay_total: 'Total',
  cart_remove: 'Remove',
  cart_subtotal: 'Subtotal',
  cart_tax: 'Taxes',

  general_back: 'Back',
  general_next: 'Next',
  general_close: 'Close',
  general_select: 'Select',

  home_access_title_part_1: 'What is MovOn',
  home_access_title_part_2: ' access?',
  home_access_description: 'MovOn embodies the idea of a perpetual movement forward. This concept extends '
   + 'beyond a simple invitation to physical movement, instead encompassing constant and collective progress.',
  home_access_left_card_part_1: 'For ',
  home_access_left_card_part_2: ' to cultural, sport events, or tourist attractions, MovOn encourages '
  + 'going beyond the experience and embracing the idea that every moment is an opportunity for growth '
  + 'and improvement of our collective well-being.',
  home_access_left_card_visitors: 'visitors',
  home_access_right_card_part_1: 'For you, ',
  home_access_right_card_part_2: ', MovOn is a call to innovation, adaptability, and constant '
  + 'exploration of ways to improve and transform the experiences you can offer.',
  home_access_right_card_organizers: 'organizers',

  home_faq_title: 'Frequently Asked Questions',
  home_faq_description: 'Welcome to our FAQ section! Find answers to frequently asked questions about our '
  + 'products, services, and policies here. If you have other questions, feel free to contact us. We are '
  + 'here to simplify your experience.',
  home_faq_subtitle_1: 'Is MovOn.VIP for you?',
  home_faq_answer_1: 'Are you an attraction manager or event organizer, and there may be waiting time at the '
  + 'entrance? MovOn.VIP is perfect for you. For example, you may be in one of the following contexts: major '
  + 'events, concerts and festivals, sports stadiums, museums and exhibitions, public urban attractions, '
  + 'performance venues (general admission), amusement parks, cruises, excursion tours, natural parks, zoos '
  + 'or any other special event.',
  home_faq_subtitle_2: 'What does "MovOn" mean?',
  home_faq_answer_2: 'MovOn embodies the idea of a perpetual movement '
  + 'forward. This concept extends beyond a simple invitation to physical movement, instead encompassing '
  + 'constant and collective progress.',
  home_faq_subtitle_3: 'What does "V.I.P." mean?',
  home_faq_answer_3: 'For us, VIP means "Very Important Planet". This goes beyond the traditional notion of '
  + 'privileged status. By adopting this acronym, the focus is on the crucial importance of our planet and '
  + 'the responsibility each of us has towards humanity.',
  home_faq_subtitle_4: 'What are the 17 United Nations Sustainable Development Goals?',
  home_faq_answer_4: 'The 17 Sustainable Development Goals (SDGs) are a set of global goals established to '
  + 'address major challenges such as poverty, inequality, climate change, peace and justice. These goals '
  + 'were adopted in September 2015 as part of the 2030 Agenda for Sustainable Development. Here is the list '
  + 'of the 17 SDGs:',
  home_faq_subtitle_5: 'How much does it cost for you, as an organizer?',
  home_faq_answer_5_part_1: 'Not only does MovOn.VIP cost you nothing, but it is an additional source of income '
  + 'for you! Indeed, you receive',
  home_faq_answer_5_part_2: 'of all MovOn.VIP funds collected during your event/attraction!',
  home_faq_subtitle_6: 'In which languages is MovOn.VIP available?',
  home_faq_answer_6: 'The MovOn.VIP service is international. It is currently available in English, French '
  + 'and Spanish. Other languages will be offered soon.',
  home_faq_subtitle_7: 'As a visitor and user, do I need to install a Mobile App?',
  home_faq_answer_7: 'No. There is no mobile app to install. Everything happens online, by going to '
  + 'www.MovOn.VIP/access with your mobile phone or tablet.',
  home_faq_subtitle_8: 'As an organizer or manager, do I need to install software?',
  home_faq_answer_8: 'No. There is no software to install: everything is secure and online. Whether it\'s '
  + 'creating your organizational account, entering your event/attraction information, managing and '
  + 'controlling access with QR codes, managing detailed event reports, and finally receiving your share '
  + 'of the amounts collected.',
  home_faq_subtitle_9: 'Who is Novom Interactive?',
  home_faq_answer_9: 'Novom Interactive is a Canadian company, a university spin-off founded in 2012, '
  + 'bringing together a wonderful team of university researchers, artistic creators, experts in '
  + 'attractions and events, as well as experienced software developers. Our team is behind the design of the MovOn.VIP '
  + 'product, a solution that is intended to be simple and effective, both cutting-edge and original. '
  + 'This initiative stems from a shared desire within the group, accompanied by a sense of urgency to '
  + 'improve our planet and promote the well-being of the humans who inhabit it. With MovOn.VIP, we aspire '
  + 'to create a committed platform, conducive to collective advancement and the achievement of sustainable '
  + 'goals, for a better future for all.',
  home_faq_subtitle_10: 'During an event or for an attraction, what percentage of the revenue collected is '
  + 'redistributed to NPOs adhering to sustainable development objectives?',
  home_faq_answer_10_part_1: 'Of all funds collected through the use of MovOn.VIP during an event or for an attraction, ',
  home_faq_answer_10_part_2: ' are donated to NPOs adhering to sustainable development objectives.'
  + 'Development Goals (SDGs).',
  home_faq_goal_1: 'No Poverty',
  home_faq_goal_2: 'Zero Hunger',
  home_faq_goal_3: 'Good Health and Well-being',
  home_faq_goal_4: 'Quality Education',
  home_faq_goal_5: 'Gender Equality',
  home_faq_goal_6: 'Clean Water and Sanitation',
  home_faq_goal_7: 'Affordable and Clean Energy',
  home_faq_goal_8: 'Decent Work and Economic Growth',
  home_faq_goal_9: 'Industry, Innovation, and Infrastructure',
  home_faq_goal_10: 'Reduced Inequality',
  home_faq_goal_11: 'Sustainable Cities and Communities',
  home_faq_goal_12: 'Responsible Consumption and Production',
  home_faq_goal_13: 'Climate Action',
  home_faq_goal_14: 'Life Below Water',
  home_faq_goal_15: 'Life on Land',
  home_faq_goal_16: 'Peace, Justice and Strong Institutions',
  home_faq_goal_17: 'Partnerships for the Goals',

  home_footer_contact: 'Contact us!',
  home_footer_contact_info: 'For more information',
  home_footer_rights: '©Movon.VIP {year} - Proud Canadian creation',
  home_footer_submit: 'Submit',
  home_footer_partners: 'Our partners',
  home_footer_touch: 'Get in touch',
  home_footer_name: 'Name',
  home_footer_email: 'Email',
  home_footer_message: 'Message',

  home_intro_title: 'Save valuable time in queues',
  home_intro_subtitle: 'Now available',
  home_intro_button: 'Learn more',
  home_intro_organizer: 'Are you an event organizer?',

  home_nav_about: 'About',
  home_nav_faq: 'FAQ',
  home_nav_organizers: 'Organizers',
  home_nav_sign_up_login: 'Sign up / Login',
  home_nav_use_cases: 'Use Cases',
  home_nav_visitors: 'Visitors',

  home_organizer_question_part_1: 'Are you an event ',
  home_organizer_question_part_2: ' or tourist attraction manager?',
  home_organizer_question_organizer: 'organizer',
  home_organizer_title_part_1: 'Increase your revenue & enhance the customer ',
  home_organizer_title_part_2: '!',
  home_organizer_title_experience: 'experience',
  home_organizer_benefits: 'Benefits:',
  home_organizer_benefit_1: 'Additional income for you (yes!)',
  home_organizer_benefit_2: 'VIP treatment for visitors who desire it, right from their arrival.',
  home_organizer_benefit_3: 'Automatic contribution to sustainable development goals.',
  home_organizer_benefit_4: 'Easy and straightforward implementation of the system.',
  home_organizer_benefit_5: 'More satisfied visitors, embracing your humanitarian vision.',

  home_passport_title: 'VIP Silver or Gold Passport:',
  home_passport_subtitle: 'Which one suits you?',
  home_passport_silver_title: 'Accelerated access.',
  home_passport_gold_title: 'Immediate access.',
  home_passport_silver_description: 'With VIP Silver access, you wait wherever you like, not '
  + 'in a long queue before accessing the event or attraction. So, take this time for yourself, '
  + 'to do something more useful than waiting in line. Interesting, isnt\'t it?',
  home_passport_gold_description: 'With VIP Gold access, even better, you don\'t have to wait '
  + 'at all! As soon as the venue manager allows it, the doors are open to you, and you can '
  + 'enter upon arrive, no matter when. Cool isn\'t it?',

  home_usecases_title: 'What are the places that can benefit from MovOn.VIP privileged access?',
  home_usecases_sports: 'Sports stadiums',
  home_usecases_events: 'Major events',
  home_usecases_concerts: 'Concerts and festivals',
  home_usecases_museums: 'Museums and Exhibitions',
  home_usecases_attractions: 'Public urban attractions',
  home_usecases_venues: 'Performance venue (general admission)',
  home_usecases_parks: 'Amusement parks and popular festivals',
  home_usecases_cruises: 'Cruises and excursion tours',
  home_usecases_zoos: 'Natural parks and zoos',

  home_vip_title: 'VIP (Very Important Planet!)',
  home_vip_description: 'VIP goes beyond the traditional notion of privileged status. By adopting '
  + 'this acronym, the focus is on the crucial importance of our planet and the responsibility '
  + 'each of us has towards humanity.',
  home_vip_description_2_part_1: 'Being "',
  home_vip_description_2_part_2: '" involves putting forward the contributions and actions '
  + 'necessary to improve our planet, in alignment with the 17 United Nations Sustainable '
  + 'Development Goals. These goals, such as combating climate change, ensuring access to quality education, '
  + 'promoting gender equality, and eradicating poverty, become the pillars of the "Very Important '
  + 'Planet" vision. ',
  home_vip_goals: 'By embracing these goals, each individual becomes a crucial actor in preserving '
  + 'our planet and building a more sustainable and equitable future for all.',

  home_visitor_subtitle_part_1: 'For ',
  home_visitor_subtitle_part_2: ' of cultural events or tourist attractions:',
  home_visitor_subtitle_visitors: 'visitors',
  home_visitor_title_part_1: 'Enjoy ',
  home_visitor_title_part_2: ' access and help our ',
  home_visitor_title_faster: 'faster',
  home_visitor_title_planet: 'planet',
  home_visitor_benefits: 'Benefits:',
  home_visitor_benefit_1: 'No more waiting times on-site - Enter immediately!',
  home_visitor_benefit_2: 'Enjoy VIP treatment to access your event/attraction.',
  home_visitor_benefit_3: 'wait wherever you like; then at the right time, receive your entry notification.',
  home_visitor_benefit_4: 'your access is immediate and priority, no matter when you arrive.',
  home_visitor_benefit_5: 'Your VIP micro-payment also contributes to helping the planet.',
  home_visitor_benefit_6: 'Have only a beautiful experience, from A to Z, without possible inconveniences.',
  home_visitor_vip_gold: 'VIP Gold: ',
  home_visitor_vip_silver: 'VIP Silver: ',

  home_work_title: 'How does it all work?',
  home_work_visitor: 'Are you a visitor?',
  home_work_organizer: 'Are you an organizer?',
  home_work_visitor_1: 'Its very simple. On www.MovOn.VIP/access with your phone (no app to install), '
  + 'you choose your event/attraction.',
  home_work_visitor_2: 'With your micro-payment, you enter your first name and phone number (no '
  + 'user account to create). You then receive a confirmation and a QR code.',
  home_work_visitor_3: 'You are then in the "fast track", awaiting an SMS notification inviting '
  + 'you to go to the entrance when its practically your turn to enter.',
  home_work_visitor_4: 'Arriving at the entrance, simply show your QR code and enjoy immediate '
  + 'entry. Simple, isn\'t it?',
  home_work_organizer_1: 'It\'s simple. On www.MovOn.VIP/account with a computer or tablet, you enter '
  + 'the requested information to create your event/attraction. You will then have access to the '
  + 'www.MovOn.VIP/manager section where you can start managing VIP access for your visitors.',
  home_work_organizer_2: 'Then, as your visitors register as VIP visitors (Silver or Gold), you will '
  + 'see them appear on your access management page.',
  home_work_organizer_3: 'An responsive web page, www.MovOn.VIP/QRcode will also be '
  + 'provided for the "D-day", allowing you to read QR codes on your visitors phones as they arrive '
  + 'at the entrance. This will allow you to validate their VIP access and give them access. '
  + 'All financial flow management is automatically handled by the platform.',
  home_work_organizer_4: 'And at the end of the event, you can access a detailed online report, '
  + 'as well as the amount collected by bank transfer. There is no software to install: everything '
  + 'is secure and online. Isn\'t it wonderful?',

  onboarding_cause: 'Support a cause that is close to your heart.',
  onboarding_obtain: 'Get VIP access',

  movon_title: 'MovOn.VIP',

  pass_congratulations: 'Congratulations {name}!',
  pass_download_pass: 'or download your pass',
  pass_owner_gold: '{name}\'s VIP Gold Pass',
  pass_owner_silver: '{name}\'s VIP Silver Pass',
  pass_owner_silver_no_name: 'Your Pass Silver VIP',
  pass_owner_gold_no_name: 'Your Pass Gold VIP',
  pass_gold_vip: 'You are now a Gold VIP',
  pass_silver_vip: 'You are now a Silver VIP',
  pass_help: 'Need help?',
  pass_line_status: 'Waiting line status',
  pass_resend_confirmation: 'Resend confirmation',
  pass_sent_to: 'Your pass was sent to {email}',
  pass_show_pass: 'Show your pass to a cashier to skip the line',
  pass_what_next: 'What\'s next?',
  pass_qr_wallet: 'Add to Google Wallet',

  payment_information: 'Payment information',

  terms_conditions: 'Terms & Conditions',

  tickets_available: '{quantity} available',
  tickets_checkout: 'Checkout',
  tickets_gold_description: 'The VIP Gold pass gives you immediate access to the attraction.',
  tickets_gold_title: 'Skip-the-line',
  tickets_group_size_2: '{limit} ticket{limit, plural, one {} other {s}}',
  tickets_group_size: 'Max: ',
  tickets_quantity: 'Quantity',
  tickets_silver_title: 'Save your spot',
};

export default messages;
